var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      { attrs: { id: "password" } },
      [
        _c("h2", [_vm._v("请设置新密码")]),
        _vm._v(" "),
        _c("div", { staticClass: "tip" }, [_vm._v("设置密码，提高账户安全性")]),
        _vm._v(" "),
        _c(
          "van-form",
          { on: { submit: _vm.onSubmit } },
          [
            _c(
              "van-field",
              {
                attrs: {
                  placeholder: "请输入6-20位字符",
                  name: "password",
                  type: _vm.passwordType,
                },
                model: {
                  value: _vm.password,
                  callback: function ($$v) {
                    _vm.password = $$v
                  },
                  expression: "password",
                },
              },
              [
                _c("van-icon", {
                  attrs: { slot: "right-icon", name: "eye" },
                  on: { click: _vm.switchPasswordType },
                  slot: "right-icon",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "van-field",
              {
                staticStyle: { "margin-top": "15px" },
                attrs: {
                  placeholder: "请输入6-20位字符",
                  name: "repassowrd",
                  type: _vm.passwordType,
                },
                model: {
                  value: _vm.repassword,
                  callback: function ($$v) {
                    _vm.repassword = $$v
                  },
                  expression: "repassword",
                },
              },
              [
                _c("van-icon", {
                  attrs: { slot: "right-icon", name: "eye" },
                  on: { click: _vm.switchPasswordType },
                  slot: "right-icon",
                }),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticStyle: { margin: "30px 0" } },
              [
                _c(
                  "van-button",
                  {
                    attrs: {
                      size: "large",
                      type: "primary",
                      "native-type": "submit",
                      disabled: _vm.disabled,
                    },
                  },
                  [_vm._v("确定")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }