"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _wxAuthorize = _interopRequireDefault(require("@/mixins/wx-authorize"));

//
//
//
//
var _default = {
  mixins: [_wxAuthorize.default],
  created: function created() {
    this.wxLogin(this);
  }
};
exports.default = _default;