"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

require("core-js/modules/es6.function.name");

var _vuex = require("vuex");

var _area = _interopRequireDefault(require("@/components/Address/area.json"));

var _index = require("@/api/user/index");

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _default = {
  data: function data() {
    return {
      areaList: _area.default,
      searchResult: []
    };
  },
  mounted: function mounted() {
    // this.$toast('加载中....');
    console.log(this.editAddress);
  },
  computed: {// ...mapGetters(['editAddress'])
  },
  methods: {
    onSave: function onSave(form) {
      var _this = this;

      var obj = this;
      (0, _index.userAddressAdd)({
        name: form.name,
        phone: form.tel,
        code: form.areaCode,
        address: form.addressDetail,
        is_default: form.isDefault ? 1 : 0
      }).then(function (res) {
        if (!res.code) {
          _this.$toast.success({
            message: '保存成功',
            duration: 500,
            onClose: function onClose() {
              obj.$router.push({
                path: '/user/address'
              });
            }
          });
        } else {
          _this.$toast(res.message);
        }
      });
    },
    onDelete: function onDelete() {
      this.$toast('删除操作');
    },
    onChangeDetail: function onChangeDetail(val) {},
    goBack: function goBack() {
      this.$router.go(-1);
    }
  }
};
exports.default = _default;