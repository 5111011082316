var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-field", {
        attrs: { label: _vm.$attrs.label, "input-align": "right" },
        on: {
          click: function ($event) {
            _vm.dialogVisable = !_vm.dialogVisable
          },
        },
        model: {
          value: _vm.result,
          callback: function ($$v) {
            _vm.result = $$v
          },
          expression: "result",
        },
      }),
      _vm._v(" "),
      _c(
        "div",
        [
          _c(
            "van-dialog",
            {
              attrs: {
                title: _vm.dialogTitle,
                "show-cancel-button": "",
                beforeClose: _vm.confirm,
              },
              on: {
                cancel: function ($event) {
                  _vm.dialogVisable = !_vm.dialogVisable
                },
              },
              model: {
                value: _vm.dialogVisable,
                callback: function ($$v) {
                  _vm.dialogVisable = $$v
                },
                expression: "dialogVisable",
              },
            },
            [
              _c("van-field", {
                staticClass: "editName",
                attrs: {
                  placeholder: "请输入" + _vm.$attrs.label,
                  "input-align": "center",
                },
                model: {
                  value: _vm.form[_vm.$attrs.field],
                  callback: function ($$v) {
                    _vm.$set(_vm.form, _vm.$attrs.field, $$v)
                  },
                  expression: "form[$attrs.field]",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }