var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: {
          title: "关联账号",
          "left-text": "返回",
          "left-arrow": "",
          "z-index": 10,
        },
        on: {
          "click-left": function ($event) {
            return _vm.$router.push({ name: "secure" })
          },
        },
      }),
      _vm._v(" "),
      _c(
        "van-cell-group",
        { attrs: { title: "关联账号绑定设置" } },
        [
          _c("van-cell", {
            attrs: {
              title: "微信",
              icon: "wechat",
              value: _vm.wechatBindInfo,
              "is-link": "",
            },
            on: { click: _vm.click },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }