var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-wrap bg-gray" },
    [
      _c(
        "van-pull-refresh",
        {
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "van-list",
            {
              attrs: {
                "immediate-check": true,
                finished: _vm.finished,
                "finished-text": "没有更多了",
              },
              on: { load: _vm.fetchData },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _c("div", { staticClass: "scroll-table bg-white" }, [
                _c("div", { staticClass: "scroll-table-container" }, [
                  _c("div", { staticClass: "scroll-table-right" }, [
                    _c("div", { staticClass: "scroll-table-right-top" }, [
                      _c(
                        "table",
                        {
                          staticClass: "freeze-top",
                          staticStyle: {
                            transform: "translate(0px, 0px) translateZ(0px)",
                          },
                        },
                        [
                          _c("tbody", [
                            _c(
                              "tr",
                              _vm._l(_vm.hd, function (item) {
                                return _c("th", [
                                  _c(
                                    "div",
                                    {
                                      style:
                                        "width:" +
                                        (item.width ? item.width : 90) +
                                        "px",
                                    },
                                    [_vm._v(_vm._s(item.name))]
                                  ),
                                ])
                              }),
                              0
                            ),
                          ]),
                        ]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "scroll-table-right-content" }, [
                      _c(
                        "table",
                        {
                          staticStyle: {
                            transform: "translate(0px, 0px) translateZ(0px)",
                          },
                        },
                        [
                          _c(
                            "tbody",
                            _vm._l(_vm.bd, function (item, index0) {
                              return _c(
                                "tr",
                                _vm._l(item, function (item1, index1) {
                                  return _c("td", [
                                    _c(
                                      "div",
                                      {
                                        class:
                                          index1 === 0
                                            ? "text-green"
                                            : "text-black",
                                        style:
                                          "width:" +
                                          (_vm.hd[index1].width
                                            ? _vm.hd[index1].width
                                            : 90) +
                                          "px",
                                      },
                                      [_vm._v(_vm._s(item1))]
                                    ),
                                  ])
                                }),
                                0
                              )
                            }),
                            0
                          ),
                        ]
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }