"use strict";

var _interopRequireDefault = require("/Users/tanglewang/htdocs/yqd/yqdshop_h5/node_modules/_@babel_runtime@7.17.8@@babel/runtime/helpers/interopRequireDefault.js");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _vuePdf = _interopRequireDefault(require("vue-pdf"));

var _index = _interopRequireDefault(require("@/components/loading/index"));

//
//
//
//
//
//
//
//
//
var _default = {
  name: 'companyProductPdf',
  components: {
    pdf: _vuePdf.default,
    Loading: _index.default
  },
  props: {},
  data: function data() {
    return {
      numPages: '',
      pdfUrl: this.$route.query.url
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    //在业务页面修改标题
    document.title = this.$route.query.company_name + '-产品费率表';
    this.loadPdf(this.pdfUrl);
  },
  methods: {
    // pdf加载时
    loadPdf: function loadPdf(url) {
      var _this = this;

      this.$refs['Loading'].show();
      this.src = _vuePdf.default.createLoadingTask(url);
      this.src.promise.then(function (pdf) {
        _this.numPages = pdf.numPages; // 这里拿到当前pdf总页数
      });
    },
    hideLoading: function hideLoading() {
      var _this2 = this;

      setTimeout(function () {
        _this2.$refs['Loading'].hide();
      }, 300);
    }
  }
};
exports.default = _default;