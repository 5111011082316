var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper" },
    [
      _c("van-nav-bar", {
        attrs: {
          title: "微信绑定设置",
          "left-text": "返回",
          "left-arrow": "",
          "z-index": 10,
        },
        on: {
          "click-left": function ($event) {
            return _vm.$router.go(-1)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "van-cell-group",
        { attrs: { title: "关联账号绑定设置" } },
        [
          _c("van-cell", {
            attrs: { title: "微信", icon: "wechat", value: "已关联" },
            on: {
              click: function ($event) {
                return _vm.$router.push({ name: "bindWechat" })
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "actions" },
        [
          _c(
            "van-button",
            {
              staticClass: "btnSubmit",
              attrs: { type: "primary", disabled: _vm.disabled, size: "large" },
              on: { click: _vm.confirm },
            },
            [_vm._v("解除绑定")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }