var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("van-nav-bar", {
        attrs: {
          title: "我的地址",
          "left-text": "返回",
          "left-arrow": "",
          "z-index": 10,
          "right-text": "个人中心",
        },
        on: {
          "click-left": _vm.goBack,
          "click-right": function ($event) {
            return _vm.$router.push("/my")
          },
        },
      }),
      _vm._v(" "),
      _c("van-address-list", {
        attrs: {
          list: _vm.list,
          "default-tag-text": "默认",
          switchable: false,
        },
        on: { add: _vm.onAdd, edit: _vm.onEdit },
        model: {
          value: _vm.chosenAddressId,
          callback: function ($$v) {
            _vm.chosenAddressId = $$v
          },
          expression: "chosenAddressId",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }